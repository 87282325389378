import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const PhotoSection = () => {

  return (
    <section className={`bg-gray-50 py-12`}>
      <div className={`max-w-6xl mx-auto px-4 md:px-6`}>

        <div className={`w-full grid lg:grid-cols-3 gap-4 lg:gap-12`}>

          <StaticImage src={"../images/600x600.jpg"} alt={'Adrian Rowe'}/>
          <StaticImage src={"../images/600x600-2.jpg"} alt={'Adrian Rowe'}/>
          <StaticImage src={"../images/600x600-3.jpg"} alt={'Adrian Rowe'}/>

        </div>

      </div>
    </section>
  );

}

export default PhotoSection;