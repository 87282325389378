import * as React from "react";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import PageTitle from '../components/PageTitle';
import TitleSection from "../components/TitleSection";
import PricesAtHome from "../components/PricesAtHome";
import PhotoSection from "../components/PhotoSection";
import AtHomeIntro from "../components/AtHomeIntro";

const VIP = () => (
  <Layout>
    <Seo title="VIP At Home" />
    <PageTitle title={'VIP At Home'}/>

    <TitleSection title={"VIP Hairdressing At Home"}/>

    <AtHomeIntro/>
    <PhotoSection/>
    <PricesAtHome/>

  </Layout>
)

export default VIP;
