import * as React from 'react';
import Button from "./Button";
import {StaticImage} from "gatsby-plugin-image";

const AtHomeIntro = () => {

  return (
    <section>
      <div className={`relative max-w-6xl mx-auto px-4 md:px-6 pb-12`}>
        <div className={`flex flex-col lg:flex-row items-center lg:space-x-16`}>

          <div className={`w-full lg:w-2/5 mb-8 lg:mb-0`}>
            <StaticImage src={'../images/adrian-rowe.jpg'} alt={'Adrian Rowe'}/>
          </div>

          <div className={`w-full lg:w-3/5 space-y-4`}>
            <h3 className={`text-xl font-bold uppercase mb-4`}>Meet Adrian Rowe<span className={'text-red'}>.</span></h3>
            <p className={`text-xl`}>
              Adrian has been expertly cutting, colouring, styling, and blow-drying hair in and around Norwich since
              1988 and is able to offer you an exclusive ‘VIP at home’ hairdressing service.
            </p>
            <p className={`font-light`}>
              This is perfect for clients with young children, hectic lifestyles or just those that would rather relax
              and experience Adrian's renowned luxury hair service from the comfort of their own home.
            </p>

            <p className={`font-light`}>
              VIP at home is available on Mondays, Tuesdays, and Wednesdays within a 10-mile radius of Norwich city centre however,
              if you have any specific requests, we will always do our best to accommodate you where possible. Please get in touch.
            </p>

            <ul className={`font-light list-disc marker:text-red space-y-1 pl-4`}>
              <li>High-end mobile hairdressing in the comfort of your own home</li>
              <li>Available Mondays / Tuesdays / Wednesdays</li>
              <li>Covering the Norwich area</li>
            </ul>

            <div className={`flex flex-row space-x-2`}>
              <Button text={'Book Now with Adrian'} path={'/booking'}/>
            </div>

          </div>

        </div>

      </div>
    </section>
  )

}

export default AtHomeIntro;