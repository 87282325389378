import * as React from "react";

const PricesAtHome = () => {

  const womensPrices = [
    { service: 'Wash, Cut & Blowdry', price: '55.00' },
    { service: 'Wash & Cut', price: '50.00' },
    { service: 'Blowdry', price: '23.00' },
  ];

  const mensPrices = [
    {
      service: 'Gents Cut',
      price: '23.00'
    }
  ];

  const childsPrices = [
    {
      service: 'Cut',
      price: '21.95'
    }
  ];


  return (
    <section>
      <div className={`max-w-6xl mx-auto px-4 md:px-6`}>
        <div className={`py-12`}>

          <h2 className={`text-black font-bold uppercase text-3xl mb-4`} data-aos={"fade"}>VIP At Home Prices<span className={`text-red`}>.</span></h2>

          <div className={`grid lg:grid-cols-2 gap-12 lg:gap-24 pb-12`}>
            <div data-aos={"fade"} data-aos-delay={"400"}>
              <h3 className={`text-black font-bold text-2xl uppercase mb-6`}>Women</h3>

              <div className={`relative grid grid-cols-2 gap-y-3 mb-6`}>
                <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm`}>From</span>
                { womensPrices.map((item) => (
                  <>
                    <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                    <span className={`text-right`}>£{ item.price }</span>
                  </>
                ))}
              </div>
            </div>


            <div data-aos={"fade"} data-aos-delay={"800"}>
              <h3 className={`text-black font-bold text-2xl uppercase mb-6`}>Men</h3>

              <div className={`relative grid grid-cols-2 gap-y-3 mb-20`}>
                <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm`}>From</span>
                { mensPrices.map((item) => (
                  <>
                    <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                    <span className={`text-right`}>£{ item.price }</span>
                  </>
                ))}
              </div>


              <h3 className={`text-black font-bold text-2xl uppercase mb-6`}>Children</h3>

              <div className={`relative grid grid-cols-2 gap-y-3 mb-6`}>
                <span className={`absolute -top-6 right-1 text-gray-600 uppercase text-sm `}>From</span>
                { childsPrices.map((item) => (
                  <>
                    <p className={`font-light font-lg uppercase`}>{ item.service }</p>
                    <span className={`text-right`}>£{ item.price }</span>
                  </>
                ))}
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  )

}

export default PricesAtHome;